import router from '@/router';

import { getKindeClient, initializeKindeClient } from '@/services/kinde-auth';
import { initPinia } from '@/stores';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import customTheme from '@primeuix/themes/aura';
import mitt from 'mitt';
import 'primeicons/primeicons.css';
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DatePicker from 'primevue/datepicker';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Drawer from 'primevue/drawer';
import FileUpload from 'primevue/fileupload';
import IconField from 'primevue/iconfield';
import Image from 'primevue/image';
import Inplace from 'primevue/inplace';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import KeyFilter from 'primevue/keyfilter';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MeterGroup from 'primevue/metergroup';
import MultiSelect from 'primevue/multiselect';
import OverlayBadge from 'primevue/overlaybadge';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Popover from 'primevue/popover';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import SplitButton from 'primevue/splitbutton';
import Step from 'primevue/step';
import StepItem from 'primevue/stepitem';
import StepList from 'primevue/steplist';
import StepPanel from 'primevue/steppanel';
import StepPanels from 'primevue/steppanels';
import Stepper from 'primevue/stepper';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import Tag from 'primevue/tag';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VirtualScroller from 'primevue/virtualscroller';
import { createApp } from 'vue';
/*
 *
 *
 */
import App from '@/App.vue';
export const init = async () => {
  const pinia = initPinia();
  const emitter = mitt();
  await initializeKindeClient();

  const app = createApp(App);
  app.config.devtools = true;

  app.use(pinia);
  app.use(router);
  app.use(PrimeVue, {
    theme: {
      preset: customTheme,
      options: {
        cssLayer: {
          name: 'primevue',
          order: 'theme, base, primevue',
        },
      },
    },
    ripple: true,

    locale: {
      startsWith: 'Commence par',
      contains: 'Contient',
      notContains: 'Ne contient pas',
      endsWith: 'Se termine par',
      equals: 'Égal à',
      notEquals: 'Non égal à',
      noFilter: 'Pas de filtre',
      lt: 'Moins de',
      lte: 'Inférieur ou égal à',
      gt: 'Plus de',
      gte: 'Supérieur ou égal à',
      dateIs: 'La date est',
      dateIsNot: "La date n'est pas",
      dateBefore: 'La date est avant',
      dateAfter: 'La date est après',
      clear: 'Effacer',
      apply: 'Appliquer',
      matchAll: 'Correspond à tous',
      matchAny: "Correspond à n'importe lequel",
      addRule: 'Ajouter une règle',
      removeRule: 'Supprimer la règle',
      accept: 'Oui',
      reject: 'Non',
      choose: 'Choisir',
      upload: 'Téléverser',
      cancel: 'Annuler',
      completed: 'Terminé',
      pending: 'En attente',
      fileSizeTypes: ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'],
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      monthNamesShort: [
        'Janv',
        'Févr',
        'Mars',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Août',
        'Sept',
        'Oct',
        'Nov',
        'Déc',
      ],
      chooseYear: "Choisir l'année",
      chooseMonth: 'Choisir le mois',
      chooseDate: 'Choisir la date',
      prevDecade: 'Décennie précédente',
      nextDecade: 'Décennie suivante',
      prevYear: 'Année précédente',
      nextYear: 'Année suivante',
      prevMonth: 'Mois précédent',
      nextMonth: 'Mois suivant',
      prevHour: 'Heure précédente',
      nextHour: 'Heure suivante',
      prevMinute: 'Minute précédente',
      nextMinute: 'Minute suivante',
      prevSecond: 'Seconde précédente',
      nextSecond: 'Seconde suivante',
      am: 'AM',
      pm: 'PM',
      today: "Aujourd'hui",
      weekHeader: 'Sem',
      firstDayOfWeek: 0,
      showMonthAfterYear: false,
      dateFormat: 'jj/mm/aa',
      weak: 'Faible',
      medium: 'Moyen',
      strong: 'Fort',
      passwordPrompt: 'Entrer un mot de passe',
      emptyFilterMessage: 'Aucun résultat trouvé',
      searchMessage: '{0} résultats disponibles',
      selectionMessage: '{0} éléments sélectionnés',
      emptySelectionMessage: 'Aucun élément sélectionné',
      emptySearchMessage: 'Aucun résultat trouvé',
      fileChosenMessage: '{0} fichiers',
      noFileChosenMessage: 'Aucun fichier choisi',
      emptyMessage: 'Aucune option disponible',
      aria: {
        trueLabel: 'Vrai',
        falseLabel: 'Faux',
        nullLabel: 'Non sélectionné',
        star: '1 étoile',
        stars: '{star} étoiles',
        selectAll: 'Tous les éléments sélectionnés',
        unselectAll: 'Tous les éléments désélectionnés',
        close: 'Fermer',
        previous: 'Précédent',
        next: 'Suivant',
        navigation: 'Navigation',
        scrollTop: 'Défiler en haut',
        moveTop: 'Déplacer en haut',
        moveUp: 'Déplacer vers le haut',
        moveDown: 'Déplacer vers le bas',
        moveBottom: 'Déplacer en bas',
        moveToTarget: 'Déplacer vers la cible',
        moveToSource: 'Déplacer vers la source',
        moveAllToTarget: 'Déplacer tout vers la cible',
        moveAllToSource: 'Déplacer tout vers la source',
        pageLabel: 'Page {page}',
        firstPageLabel: 'Première page',
        lastPageLabel: 'Dernière page',
        nextPageLabel: 'Page suivante',
        prevPageLabel: 'Page précédente',
        rowsPerPageLabel: 'Lignes par page',
        jumpToPageDropdownLabel: 'Aller à la page (menu déroulant)',
        jumpToPageInputLabel: 'Aller à la page (champ de saisie)',
        selectRow: 'Ligne sélectionnée',
        unselectRow: 'Ligne désélectionnée',
        expandRow: 'Ligne dépliée',
        collapseRow: 'Ligne repliée',
        showFilterMenu: 'Afficher le menu de filtre',
        hideFilterMenu: 'Masquer le menu de filtre',
        filterOperator: 'Opérateur de filtre',
        filterConstraint: 'Contrainte de filtre',
        editRow: 'Modifier la ligne',
        saveEdit: 'Enregistrer la modification',
        cancelEdit: 'Annuler la modification',
        listView: 'Vue en liste',
        gridView: 'Vue en grille',
        slide: 'Diapositive',
        slideNumber: 'Diapositive {slideNumber}',
        zoomImage: "Agrandir l'image",
        zoomIn: 'Zoom avant',
        zoomOut: 'Zoom arrière',
        rotateRight: 'Pivoter à droite',
        rotateLeft: 'Pivoter à gauche',
        listLabel: 'Liste des options',
      },
    },
  });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(DialogService);

  app.directive('badge', BadgeDirective);
  app.directive('ripple', Ripple);
  app.directive('tooltip', Tooltip);
  app.directive('keyfilter', KeyFilter);

  app.component('Accordion', Accordion);
  app.component('AccordionPanel', AccordionPanel);
  app.component('AccordionHeader', AccordionHeader);
  app.component('AccordionContent', AccordionContent);

  app.component('AutoComplete', AutoComplete);
  app.component('Badge', Badge);
  app.component('Breadcrumb', Breadcrumb);
  app.component('Button', Button);
  app.component('Card', Card);
  app.component('Chart', Chart);
  app.component('Column', Column);
  app.component('ColumnGroup', ColumnGroup);
  app.component('ConfirmDialog', ConfirmDialog);
  app.component('ConfirmPopup', ConfirmPopup);
  app.component('DataTable', DataTable);
  app.component('DataView', DataView);
  app.component('DatePicker', DatePicker);
  app.component('Dialog', Dialog);
  app.component('Divider', Divider);
  app.component('Drawer', Drawer);
  app.component('FileUpload', FileUpload);
  app.component('IconField', IconField);
  app.component('Image', Image);
  app.component('Inplace', Inplace);
  app.component('InputIcon', InputIcon);
  app.component('InputNumber', InputNumber);
  app.component('InputGroup', InputGroup);
  app.component('InputGroupAddon', InputGroupAddon);
  app.component('InputText', InputText);
  app.component('InputMask', InputMask);
  app.component('Knob', Knob);
  app.component('Listbox', Listbox);
  app.component('Menu', Menu);
  app.component('MeterGroup', MeterGroup);
  app.component('Message', Message);
  app.component('MultiSelect', MultiSelect);
  app.component('OverlayBadge', OverlayBadge);
  app.component('Paginator', Paginator);
  app.component('Panel', Panel);
  app.component('Popover', Popover);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('RadioButton', RadioButton);
  app.component('Row', Row);
  app.component('SelectButton', SelectButton);
  app.component('Select', Select);
  app.component('Skeleton', Skeleton);
  app.component('Slider', Slider);
  app.component('SplitButton', SplitButton);
  app.component('Stepper', Stepper);
  app.component('StepList', StepList);
  app.component('StepPanels', StepPanels);
  app.component('StepItem', StepItem);
  app.component('Step', Step);
  app.component('StepPanel', StepPanel);
  app.component('Tabs', Tabs);
  app.component('TabList', TabList);
  app.component('Tab', Tab);
  app.component('TabPanels', TabPanels);
  app.component('TabPanel', TabPanel);
  app.component('Tag', Tag);
  app.component('Timeline', Timeline);
  app.component('Toast', Toast);
  app.component('VirtualScroller', VirtualScroller);

  app.config.globalProperties.emitter = emitter;
  app.provide('emitter', emitter);

  const kinde = getKindeClient();
  const isAuthenticated = await kinde.isAuthenticated();
  const user = await kinde.getUser();
  const token = await kinde.getToken();
  const appStore = useAppStore();
  const authStore = useAuthStore();
  if (true === !!isAuthenticated && true === !!token) {
    try {
      authStore.accessToken = token;
      const me = await authStore.getMe();
      authStore.isAuthenticated = true;
      authStore.userProfile = me;

      Promise.allSettled([appStore.getGlobalSettings()]).then(results => {
        appStore.loading = true;
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            // console.log(`Data ${index + 1}:`, result.value);
          } else {
            console.error(`Error fetching data ${index + 1}:`, result.reason);
          }
        });
        appStore.loading = false;
      });
    } catch (error) {
      router.push({ name: 'Unauthorized' });
      console.log(error.message);
    }
  } else {
    authStore.isAuthenticated = false;
    authStore.userProfile = null;
    authStore.accessToken = null;
  }
  // Mount
  app.mount('#app');
};
