import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { ref } from 'vue';
export const useModalStore = defineStore('modal', () => {
  const title = ref('');
  const subtitle = ref('');
  const titleIcon = ref(null);
  const titleClasses = ref('');
  const isOpen = ref(false);
  const btnCloseEnabled = ref(true);
  const modalContentView = ref(null);
  const modalContentKey = ref(uuidv4());
  const modalContentProperties = ref({});
  const sizeClasses = ref(['!w-5/6', '!2xl:w-1/3']);
  const layout = ref('default');

  const openDialog = (view = null, options = {}) => {
    if (!view) {
      isOpen.value = false;
      return;
    }
    modalContentKey.value = 'uuidv4()';
    modalContentView.value = view;
    modalContentProperties.value = options?.payload ?? {};
    title.value = options?.title ?? '';
    subtitle.value = options?.subtitle ?? '';
    titleIcon.value = options?.titleIcon ?? '';
    if (options?.sizeClasses ?? []) {
      sizeClasses.value = [...options.sizeClasses];
    }
    titleClasses.value = options?.titleClasses ?? ['text-sm', 'lg:text-xl'];
    isOpen.value = true;
  };
  const closeDialog = () => {
    modalContentKey.value = uuidv4();
    modalContentView.value = null;
    modalContentProperties.value = {};
    isOpen.value = false;
    title.value = '';
    subtitle.value = '';
  };

  return {
    title,
    subtitle,
    titleClasses,
    sizeClasses,
    isOpen,
    btnCloseEnabled,
    modalContentView,
    modalContentKey,
    modalContentProperties,
    layout,
    openDialog,
    closeDialog,
  };
});
